import $api from './api'
import { TIMEOUT, ERR_OK, baseURL, STATUS, local } from './config'

/**
 * 消费人群和消费金额饼图
 */
export const consumerStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/consumer/consumerStatistics',
        params
    })
}


/**
 * 商户区域分布情况
 */
export const regionalDistributionInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/bus/regionalDistributionInfo',
        params
    })
}

/**
 * 消费结构区域分布
*/
 export const regionalConsumerInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/consumer/regionalConsumerInfo',
        params
    })
}

/**
 * 消费人群、金额新增趋势
 */
export const consumerAmountTrend = (params) => {
    return $api.post({
        url: baseURL + '/api/consumer/consumerAmountTrend',
        params
    })
}

/**
 * 消费人群、金额累计趋势
*/
 export const consumerAmountCumulative = (params) => {
    return $api.post({
        url: baseURL + '/api/consumer/consumerAmountCumulative',
        params
    })
}


/**
 * 消费结构按城市/地区统计
*/
export const consumerAmountCityStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/consumer/consumerAmountCityStatistics',
        params
    })
}


/**
 * 消费结构按领域/行业统计
*/
export const consumerAmountIndustryStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/consumer/consumerAmountIndustryStatistics',
        params
    })
}